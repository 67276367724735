<template>
  <div>
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card class="mx-auto" max-width="300" tile color="#62B6DC">
        <v-toolbar dark color="#62B6DC" elevation="0">
          <v-toolbar-title>Address</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click.native="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-list dense color="#62B6DC" dark>
          <v-list two-line color="#62B6DC">
            <v-list-item-group active-class="pink--text" multiple>
              <template v-for="(item, index) in searchPostCodeDialog">
                <v-list-item
                  :key="item.summaryline"
                  @click="selectpostcodefromlist(item)"
                >
                  <template>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="item.summaryline"
                      ></v-list-item-title>
                    </v-list-item-content>

                    <v-list-item-action> </v-list-item-action>
                  </template>
                </v-list-item>

                <v-divider
                  v-if="index < items.length - 1"
                  :key="index"
                ></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-list>
      </v-card>
    </v-dialog>
    <v-container>
      <transition-group name="list-animated">
        <v-row
          v-for="(line, index) in formData.lines"
          :key="index"
          class="rounder mt-4 mb-7 pt-4 list-animated-item"
        >
          <v-col>
            <v-row no-gutters class="row">
              <v-col cols="12">
                <div style="position: relative">
                  <v-btn
                    fab
                    dark
                    x-small
                    absolute
                    top
                    elevation="0"
                    color="#57b3dd"
                    @click="removeAddress(index)"
                    v-if="formData.lines.length > 1"
                    style="right: 0px; top: -45px"
                  >
                    <v-icon dark> mdi-close </v-icon>
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="12">
                <p
                  class="text-h6 text-justify font-weight-bold"
                  style="display: none"
                >
                  Address {{ index + 1 }}
                </p>
                <v-text-field
                  label="Postcode"
                  :rules="[rules.required]"
                  v-model="line.postcode"
                  flat
                  solo
                  large
                  block
                  outlined
                  hide-details="auto"
                  placeholder="Please enter postcode"
                  class="mb-3"
                  @keydown.enter="searchPostcode(index)"
                >
                  <template #append>
                    <v-btn
                      color="secondary"
                      plain
                      @click="searchPostcode(index)"
                      :loading="isSearching"
                      ><v-icon left> mdi-magnify </v-icon>
                      Search
                    </v-btn>
                  </template>
                </v-text-field>

                <v-text-field
                  v-if="line.housenumber"
                  label="House Number"
                  v-model="line.housenumber"
                  large
                  block
                  outlined
                  hide-details="auto"
                  class="mb-3"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-if="line.city"
                  label="City / Town"
                  v-model="line.city"
                  large
                  block
                  outlined
                  hide-details="auto"
                  class="mb-3"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="pl-0 pr-2">
                <v-text-field
                  v-if="line.housenumber"
                  label="County"
                  v-model="line.county"
                  large
                  block
                  outlined
                  hide-details="auto"
                  class="mb-3"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-if="line.housenumber"
                  label="Postcode"
                  v-model="line.postcode"
                  large
                  block
                  outlined
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="6" class="pl-0 pr-2">
                <v-text-field
                  v-if="line.housenumber"
                  :rules="[rules.moreThanThreeYears]"
                  label="Year"
                  v-model="line.year"
                  large
                  block
                  outlined
                  type="number"
                  hide-details="auto"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-if="line.housenumber"
                  label="Month"
                  v-model="line.month"
                  large
                  block
                  outlined
                  type="number"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="pt-0 mt-0" style="position: relative">
                <div
                  style="
                    text-align: right;
                    width: 100%;
                    font: normal normal normal 12px/16px Nunito;
                    letter-spacing: 0.36px;
                    color: #606368;
                  "
                >
                  We need 3 years of address history.
                </div>
                <v-btn
                  large
                  block
                  outlined
                  color="#ffb3b3"
                  v-if="formData.lines.length > 1 && false"
                  @click="removeAddress(index)"
                  class="cta"
                >
                  &minus; Remove Address {{ index + 1 }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </transition-group>
    </v-container>
    <v-container>
      <v-row>
        <v-col>
          <div class="dotted-line"></div>

          <v-btn
            class="mt-2 cta"
            large
            block
            outlined
            color="#6dbce1"
            @click="addLine"
          >
            &plus; Add another address
          </v-btn>
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar
      v-model="snackbar"
      :color="snackbarColor"
      multi-line
      centered
      timeout="2000"
    >
      {{ snackbarText }}
      <v-btn text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  name: "AddressInput",
  data() {
    return {
      isSearching: false,
      snackbar: false,
      snackbarText: "",
      snackbarColor: "success",
      rules: {
        required: (value) => !!value || "Required.",
        moreThanThreeYears: (value) => this.totalMonths >= 36 || "",
        //counter: (value) => value.length >= 3 || "Min 3 characters",
        counter: (value) => (value && value.length >= 3) || "Min 3 characters",

        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Invalid e-mail.";
        },
      },
      currentindex: 0,
      items: [
        {
          summaryline: "Ali Connors",
        },
        {
          summaryline: "me, Scrott, Jennifer",
        },
        {
          summaryline: "Sandra Adams",
        },
        {
          summaryline: "Trevor Hansen",
        },
        {
          summaryline: "Britta Holt",
        },
      ],
      dialog: false,
      lines: [
        {
          countryCode: null,
          number: null,
          phoneUsageType: null,
        },
      ],
      blockRemoval: true,
      phoneUsageTypes: [
        {
          label: "Home",
          value: "home",
        },
        {
          label: "Work",
          value: "work",
        },
        {
          label: "Mobile",
          value: "mobile",
        },
        {
          label: "Fax",
          value: "fax",
        },
      ],
      countryPhoneCodes: [
        {
          label: "+90",
          value: "+90",
        },
        {
          label: "+1",
          value: "+1",
        },
      ],
    };
  },
  computed: {
    ...mapState(["firebase_token", "formData", "searchPostCodeDialog"]),
    totalMonths() {
      return this.formData.lines.reduce(
        (total, item) =>
          total + (parseInt(item.year) || 0) * 12 + (parseInt(item.month) || 0),
        0,
      );
    },
  },
  watch: {
    lines() {
      this.blockRemoval = this.lines.length <= 1;
    },
  },
  methods: {
    ...mapActions(["removeAddress", "updateFormData"]),
    showSnackbar(message, color) {
      this.snackbarText = message;
      this.snackbarColor = color || "success";
      this.snackbar = true;
    },
    selectpostcodefromlist(item) {
      this.dialog = false;
      this.formData.lines[this.currentindex].housenumber = item.premise.concat(
        ", ",
        item.street,
        item.dependentlocality ? ", " + item.dependentlocality : "",
      );
      this.formData.lines[this.currentindex].city = item.posttown;
      this.formData.lines[this.currentindex].county = item.county;
      this.formData.lines[this.currentindex].postcode = item.postcode;
      this.updateFormData(this.formData);
    },
    searchPostcode(index) {
      this.isSearching = true;
      this.$store
        .dispatch("searchPostcode", {
          payload: {
            operations: "function3",
            data: {
              postcode: this.formData.lines[index].postcode,
            },
          },
        })
        .then((response) => {
          this.currentindex = index;
          this.isSearching = false;

          if (this.searchPostCodeDialog.length === 0) {
            this.showSnackbar(
              "No address found with that postcode, please try again.",
              "error",
            );
          } else {
            this.dialog = true;
          }
        });
    },
    addLine() {
      let checkEmptyLines = this.lines.filter((line) => line.number === null);
      // if (checkEmptyLines.length >= 1 && this.lines.length > 0) {
      //   return;
      // }
      this.formData.lines.push({
        city: null,
        county: null,
        postcode: null,
        housenumber: null,
        year: null,
        month: null,
      });
    },
    removeLine(lineId) {
      if (!this.blockRemoval) {
        this.formData.lines.splice(lineId, 1);
      }
    },
  },
  mounted() {
    //this.addLine();
    // this.formData.lines.push({
    //   countryCode: null,
    //   number: null,
    //   phoneUsageType: null,
    // });
  },
};
</script>
<style>
.rounder {
  border: 2px solid #dadbdf;
  border-radius: 0.85rem;
}
.dotted-line {
  border-top: 1px dashed #dadbdf; /* Adjust color as needed */
  width: 100%;
  margin: 25px 0;
}
.list-animated-item {
  transition: opacity 0.5s;
}
.list-animated-enter {
  opacity: 0;
}
.list-animated-move {
  opacity: 0;
}
.list-animated-leave-to {
  opacity: 0;
}
</style>
